.CustomerDataDetails{
    padding: 3em;
    margin-bottom: 2em;
    border-color: #bbdefb;
    border-radius: 0.3em;
    border : 1px solid;
}

.CustomerDataDetails h1{
    font-size: 1.5em;
    margin-top: 0.5em;
}

.CustomerDataDetails p{
    margin-bottom: 0;
}

.CustomerDataDetails a{
    word-break: break-all;
}