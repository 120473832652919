body{
  background-color: aliceblue;
}

pre {
  background: #f4f5f6;
  border-left: 0.3rem solid #1d6e96;
}

a {
  color: #1d6e96;
  text-decoration: none;
}

select:focus {
  border: 0.1rem solid #1d6e96;
  outline: 0;
}

hr {
  border-top: 0.1rem solid #1d6e96;
  border-width: 0.1rem;
  margin-bottom: 3.5rem;
  margin-top: 3rem;
}

input[type="submit"].button-clear {
  color: #1d6e96;
  background-color: transparent;
  border-color: transparent;
}
.button.button-outline,
button.button-outline,
input[type="button"].button-outline,
input[type="reset"].button-outline,
input[type="submit"].button-outline {
  color: #1d6e96;
  background-color: transparent;
}
.button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="submit"] {
  background-color: #1d6e96;
  border: 0.1rem solid #1d6e96;
  border-radius: 0.4rem;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  height: 3.8rem;
  letter-spacing: 0.1rem;
  line-height: 3.8rem;
  padding: 0 3rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

input[type="text"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border: 0.1rem solid #1d6e96;
  outline: 0;
  background-color: white;
}

input[type="text"],
input[type="url"],
input[type="password"],
textarea,
select {
  background-color: white;
}

.button.button-clear, button.button-clear, input[type="button"].button-clear, input[type="reset"].button-clear, input[type="submit"].button-clear{
  color:#1d6e96;
}