Nav.menu ul{
    list-style: none !important;
    display: flex;
}

Nav.menu ul li a{
    font-size: 0.9em;
}

Nav.menu .clearfix > div{
    margin:1em;
}

.button.button-outline.quit{
    border-color: #e57373;
    color:#e57373;
}
.button.button-outline.quit:hover{
    border-color: #eb5757;
    color:#eb5757;
}

.user{
    font-weight: 700;
}

.Nav h1{
    font-size: 1.2em;
    color: #b14343;
}

.Nav img{
    width:30%;
}