.Preview{
    padding: 1em;
    margin-bottom: 2em;
    border-color: #bbdefb;
    border-radius: 0.3em;
    border : 1px solid;
}

.Preview h1{
    font-size: 1.5em;
}

.Preview p{
    margin:0;
}