.CampaignForm .button.delete {
  background-color: #f44336;
  border-color: #f44336;
}

/* Custom size */
.CampaignForm .button-small {
  font-size: 0.8rem;
  height: 2.8rem;
  line-height: 2.8rem;
  padding: 0 1.5rem;
}

.CampaignForm .button-large {
  font-size: 1.4rem;
  height: 4.5rem;
  line-height: 4.5rem;
  padding: 0 2rem;
}

.CampaignForm .preview-form {
  padding: 2em;
  border-radius: 0.5em;
  border: solid 1px;
  margin-left: 2em;
}

.CampaignForm .success{
  color: #81c784;
  font-weight: bold;
}

.CampaignForm .doing{
  color: #ff8800;
  font-weight: bold;
  animation: blinker 1s linear infinite;
}

.CampaignForm .todo{
  color: #ff3d00;
  font-weight: bold;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}