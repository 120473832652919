.Status{
    border-radius: 5px;
    padding: 0.2em;
    text-align: center;
    font-weight: 700;
    width: 7em;
    height: 7em;
    border-radius: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Status p{
    font-size: 0.8em;
    margin: 0;
    padding: 0;
}

.Status.status-0{
    color:#00bcd4;
}
.Status.status-1{
    color: #ffb74d;
}
.Status.status-2{
    color: #81c784;
}