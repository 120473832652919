.Signin h2{
    font-size: 1.5em;
    color: #b14343;
}

.Signin img{
    width: 50%;
}

.Signin{
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.Signin label, .Signin input{
    text-align: left;
}